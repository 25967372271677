.loader {
  @apply flex items-center justify-center absolute inset-0 pointer-events-none;

  &-progress {
    animation: progress 1.8s linear infinite;

    @apply text-40 text-primary;
  }

  &-icon {
    animation: loader 1.5s ease-in-out infinite;
  }
}

.preloader {
  @apply flex items-center justify-center text-primary;

  &-circle {
    animation: preloader-circle 1.2s linear infinite;

    @apply bg-current rounded-inherit w-10 h-10;
  }

  &-bullet {
    animation: preloader-bullet 1.3s ease infinite both;

    &:nth-child(1) {
      animation-delay: 0.4s;
    }

    &:nth-child(2) {
      animation-delay: 0.6s;
    }

    &:nth-child(3) {
      animation-delay: 0.8s;
    }

    @apply bg-current rounded-max w-8 h-8;
  }

  &-line {
    animation: preloader-line 1.2s infinite ease-in-out;

    &:nth-child(1) {
      animation-delay: -1.1s;
    }

    &:nth-child(2) {
      animation-delay: -1.0s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }

    &:nth-child(4) {
      animation-delay: -0.8s;
    }

    &:nth-child(5) {
      animation-delay: -0.7s;
    }

    @apply bg-current w-3 h-20;
  }

  &-square {
    animation: preloader-square 1s ease infinite;

    &:nth-child(2) {
      animation-delay: 0.25s;
    }

    &:nth-child(3) {
      animation-delay: 0.75s;
    }

    &:nth-child(4) {
      animation-delay: 0.5s;
    }


    @apply bg-current opacity-40 w-7 h-7;
  }

  &--circles {

    &::before,
    &::after {
      border-width: 12px;

      @apply content-auto absolute inset-0 rounded-inherit border-solid border-inherit;
    }

    &::before {
      animation: progress 1s linear infinite;

      @apply border-x-transparent;
    }

    &::after {
      animation: progress 1.2s linear infinite;

      @apply border-y-transparent;
    }

    @apply relative bg-transparent rounded-max w-24 h-24;
  }

  &--bullets {
    @apply gap-5;
  }

  &--lines {
    @apply gap-2;
  }

  &--squares {
    @apply flex-wrap gap-1 w-16;
  }

  &--pulse {

    &::before,
    &::after {
      animation: preloader-pulse 1s ease-out infinite;

      @apply content-auto absolute inset-0 border-4 border-solid border-current rounded-max w-full h-full;
    }

    &::after {
      animation-delay: -0.5s;
    }

    @apply relative w-20 h-20;
  }
}

@keyframes progress {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    stroke: {
      dasharray : 1px, 200px;
      dashoffset: 0;
    }
  }

  50% {
    stroke: {
      dasharray : 100px, 200px;
      dashoffset: -15px;
    }
  }

  100% {
    stroke: {
      dasharray : 100px, 200px;
      dashoffset: -125px;
    }
  }
}

@keyframes preloader-circle {

  0%,
  100% {
    transform: scale(0.4);

    @apply opacity-20;
  }

  50% {
    transform: scale(1);

    @apply opacity-100;
  }
}

@keyframes preloader-bullet {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes preloader-line {

  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1.0);
  }
}

@keyframes preloader-square {

  0%,
  100% {
    @apply opacity-100;
  }

  25%,
  50%,
  75% {
    @apply opacity-40;
  }
}

@keyframes preloader-pulse {

  0% {
    transform: scale(0);

    @apply opacity-100;
  }

  100% {
    transform: scale(1);

    @apply opacity-0;
  }
}