.quantity {
  @apply flex items-center justify-between border border-solid border-gray rounded-full w-max p-1;

  &-btn {
    @apply rounded-max w-8 h-8;
  }

  &-cover {
    @apply w-10;
  }

  &-input {
    @apply bg-transparent text-center border-none h-auto p-0;
  }
}