@mixin range {
  width : var(--bubble-size);
  height: var(--bubble-size);

  @apply bg-transparent border-none opacity-0 cursor-ew-resize pointer-events-auto appearance-none;
}

.range {
  --bubble-size: 28px;

  &-cover {
    @apply w-16;
  }

  &-wrapper {

    &:nth-child(2) {
      @apply -mt-2;
    }

    @apply relative rounded-1 h-2;
  }

  &-input {
    @apply block rounded-inherit appearance-none w-full h-full;

    &::-webkit-slider-thumb {
      @include range();
    }

    &::-moz-range-thumb {
      @include range();
    }

    &::-ms-thumb {
      @include range();
    }
  }

  &-progress {
    @apply absolute z-1 top-0 bottom-0 rounded-inherit pointer-events-none h-full;
  }

  &-bubble {
    width     : var(--bubble-size);
    height    : var(--bubble-size);
    box-shadow: 0 0 0 2px theme("colors.primary.DEFAULT / 40%");

    @apply flex items-center justify-center absolute z-2 top-0 bottom-0 text-12 rounded-max border-2 border-solid border-grey pointer-events-none my-auto;
  }
}