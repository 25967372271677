.btn {
  &-rect {

    &::before,
    &::after {
      @apply border-2 border-solid border-transparent w-5 h-5;
    }

    &::before {
      @apply top-0 left-0 border-t-current border-l-current;
    }

    &::after {
      @apply right-0 bottom-0 border-r-current border-b-current;
    }

    @media(hover) {

      &:hover::before,
      &:hover::after {
        width : calc(100% + 10px);
        height: calc(100% + 10px);
      }
    }
  }

  &-frame {

    &::before,
    &::after {
      @apply border-2 border-solid border-transparent w-10 h-5;
    }

    &::before {
      @apply top-0 right-0 border-t-current border-r-current;
    }

    &::after {
      @apply bottom-0 left-0 border-b-current border-l-current;
    }

    @media(hover) {

      &:hover::before,
      &:hover::after {
        width : calc(100% + 5px);
        height: calc(100% + 5px);

        @apply border-current;
      }
    }
  }

  &-lines {

    &::before,
    &::after {
      @apply top-full right-0 left-0 bg-current border-none w-4/5 h-0.5 mx-auto;
    }

    @media(hover) {
      &:hover::before {
        @apply top-0 w-full;
      }

      &:hover::after {
        @apply w-full;
      }
    }
  }

  &-rect,
  &-frame,
  &-lines {

    &::before,
    &::after {
      @apply content-auto absolute rounded-inherit duration-5;
    }

    @apply rounded-none;
  }
}