.postcards {
  perspective: 600px;

  &-item {
    transform         : rotateX(40deg);
    transform-style   : preserve-3d;
    perspective-origin: center center;

    @apply relative bg-grey w-40 h-40 ease-linear duration-4;

    @media(hover) {
      &:hover {
        transform: translateZ(100px) rotateX(20deg);

        @apply z-1;

        .postcards-particle {
          filter: grayscale(0);

          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(7),
          &:nth-child(9) {
            transform: rotateY(0);
          }

          &:nth-child(3),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(8) {
            transform: rotateX(0);
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            @apply delay-0;
          }

          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9) {
            @apply delay-2;
          }
        }
      }
    }
  }

  &-particle {
    background-size    : 300% 300%;
    backface-visibility: hidden;
    transition         : transform 0.3s, filter 1s;
    filter             : grayscale(0.9);

    &:nth-child(1) {
      @apply bg-center;
    }

    &:nth-child(2) {
      transform: rotateY(180deg);

      @apply top-0 left-full bg-right origin-left delay-1;
    }

    &:nth-child(3) {
      transform: rotateX(-180deg);

      @apply top-full left-0 bg-bottom origin-top delay-1;
    }

    &:nth-child(4) {
      transform: rotateY(-180deg);

      @apply top-0 -left-full bg-left origin-right delay-1;
    }

    &:nth-child(5) {
      transform: rotateX(180deg);

      @apply -top-full left-0 bg-top origin-bottom delay-1;
    }

    &:nth-child(6) {
      transform: rotateX(-180deg);

      @apply top-full left-full bg-right-bottom origin-top delay-0;
    }

    &:nth-child(7) {
      transform: rotateY(180deg);

      @apply top-full -left-full bg-left-bottom origin-right delay-0;
    }

    &:nth-child(8) {
      transform: rotateX(180deg);

      @apply -top-full -left-full bg-left-top origin-bottom delay-0;
    }

    &:nth-child(9) {
      transform: rotateX(180deg);

      @apply -top-full left-full bg-right-top origin-left delay-0;
    }

    @apply absolute bg-no-repeat w-full h-full;
  }
}