.btn[data-bubles="show"] {

  &::before,
  &::after {
    width: 150%;

    @apply content-auto absolute left-1/2 bg-no-repeat -translate-x-1/2 h-full;
  }

  &::before {
    animation: top-bubbles 0.6s ease-in-out infinite;

    @apply -top-3/4;

    background: {
      position: 5% 90%, 10% 90%, 10% 90%, 10% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
      image   : radial-gradient(circle, var(--btn-color) 20%, transparent 20%),
        radial-gradient(circle, transparent 20%, var(--btn-color) 20%, transparent 30%),
        radial-gradient(circle, var(--btn-color) 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, var(--btn-color) 15%, transparent 20%),
        radial-gradient(circle, var(--btn-color) 20%, transparent 20%),
        radial-gradient(circle, var(--btn-color) 20%, transparent 20%),
        radial-gradient(circle, var(--btn-color) 20%, transparent 20%),
        radial-gradient(circle, var(--btn-color) 20%, transparent 20%);
      size: 10px 10px, 20px 20px, 15px 15px, 20px 20px, 18px 18px, 10px 10px, 15px 15px, 18px 18px;
    }
  }

  &::after {
    animation: bottom-bubbles 0.6s ease-in-out infinite;

    @apply -bottom-3/4;

    background: {
      position: 10% 0, 30% 10%, 55% 0, 70% 0, 85% 0, 70% 0, 70% 0;
      image   :
        radial-gradient(circle, var(--btn-color) 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, var(--btn-color) 15%, transparent 20%),
        radial-gradient(circle, var(--btn-color) 20%, transparent 20%),
        radial-gradient(circle, var(--btn-color) 20%, transparent 20%),
        radial-gradient(circle, var(--btn-color) 20%, transparent 20%),
        radial-gradient(circle, var(--btn-color) 20%, transparent 20%),
        radial-gradient(circle, var(--btn-color) 20%, transparent 20%);
      size: 15px 15px, 20px 20px, 18px 18px, 20px 20px, 15px 15px, 20px 20px, 18px 18px;
    }
  }
}

@keyframes top-bubbles {
  50% {
    background-position: 0 80%, 0 20%, 10% 40%, 20% 0, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }

  100% {
    background: {
      position: 0 70%, 0 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
      size    : 0 0, 0 0, 0 0, 0 0, 0 0, 0 0;
    }
  }
}

@keyframes bottom-bubbles {
  50% {
    background-position: 0 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 95% 0;
  }

  100% {
    background: {
      position: 0 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 100% 10%;
      size    : 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0;
    }
  }
}