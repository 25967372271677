// Инициализация тайлвинд
@tailwind base;
@tailwind components;
@tailwind utilities;

// Базовые стили
@layer base {
  @import "@fancyapps/ui/dist/fancybox/fancybox.css";
  @import "swiper/css/bundle";
  @import "air-datepicker/air-datepicker.css";
  @import "./ui-kit/fonts";
  @import "./ui-kit/basic";
  @import "./ui-kit/scrollbar";
  @import "./ui-kit/custom";
  @import "./ui-kit/waved";
  @import "./ui-kit/movement";
}

// Компоненты
@layer components {
  @import "./ui-kit/inverted";
  @import "./ui-kit/postcards";
  @import "./ui-kit/like";
  @import "./ui-kit/load";
  @import "./ui-kit/button-swipe";
  @import "./ui-kit/button-glow";
  @import "./ui-kit/button-bubles";
  @import "./ui-kit/button-border";
  @import "./ui-kit/quantity";
  @import "./ui-kit/rating";
  @import "./ui-kit/range";
}

// Навигация
.nav {
  &__accordion[data-accordion="active"] &__arrow {
    @apply rotate-90;
  }

  &__accordion[data-accordion="active"] &__items {
    @apply top-10;
  }
}

// Меню
.menu {
  &[data-sidebar="open"] {
    @apply visible opacity-100;
  }

  &[data-sidebar="open"] &__content {
    @apply visible opacity-100 translate-x-0;
  }

  &[data-sidebar="open"] &__head {
    @apply translate-y-0;
  }

  &[data-sidebar="open"] &__nav {
    @apply visible opacity-100 translate-x-0;
  }

  &[data-sidebar="open"] &__footer {
    @apply visible opacity-100 translate-y-0;
  }

  &__content {
    box-shadow: 0 0 0 100vw theme("colors.black.DEFAULT / 50%");

    @apply -translate-x-full;
  }

  &__head {
    @apply -translate-y-full;
  }

  &__nav {
    @apply -translate-x-full;
  }

  &__footer {
    @apply translate-y-1/4;
  }

  &__accordion[data-accordion="active"] &__arrow {
    @apply rotate-90;
  }
}

// Перетаскиваемое социальное меню
.social {
  left: calc(100vw - 150px);

  &__round[data-social-round="hidden"] {
    @apply scale-0 -rotate-180;
  }

  &__btn {
    &::before {
      @apply content-auto block bg-current rounded-inherit w-full h-full;
    }
  }
}

// Переключатель темы
.theme {
  left: calc(100vw - 240px);
}

// Снежинки
.snowflake {
  background-image: url("../img/pictures/snow.png");
  animation       : snowflake 3s linear both 1;

  @apply absolute bg-no-repeat bg-center bg-contain;
}

@keyframes snowflake {
  0% {
    transform: translate(-50%, 0);
    filter   : hue-rotate(0deg);

    @apply opacity-100;
  }

  100% {
    transform: translate(-50%, 1000%);
    filter   : hue-rotate(500deg);

    @apply opacity-0;
  }
}

// Бегующая кнопка
.running {
  @media(pointer: coarse) {
    @apply hidden;
  }
}

// Покупка
.shop {
  &[data-shop="hidden"] {
    @apply -translate-y-1/3;
  }

  &-add {
    animation: shop-add 2s ease both;
  }
}

@keyframes shop-add {
  100% {
    @apply -top-24 left-full;
  }
}

// Документы
.documents {
  &__accordion[data-accordion="active"] &__arrow {
    @apply rotate-90;
  }
}

// Фильтр
.filtering {
  &__category.filtering-active {
    @apply text-white bg-second pointer-events-none;

    @screen md {
      @apply font-bold text-primary bg-opacity-0;
    }
  }

  &__card.filtering-active {
    animation: filtering-active 0.3s ease both 1;
  }
}

@keyframes filtering-active {
  0% {
    transform: translateY(50px);

    @apply opacity-0;
  }

  100% {
    transform: translateY(0);

    @apply opacity-100;
  }
}

// Плеер
.player {
  &__loading {
    animation: loading-player 1s steps(8) infinite;
  }

  &__range {
    &::after {
      @apply content-auto absolute top-0 bottom-0 -right-1 bg-current rounded-max w-3 h-3 my-auto;
    }
  }
}

@keyframes loading-player {
  100% {
    transform: rotateZ(360deg);
  }
}

// Продукция
.product {
  @media(hover) {
    &:hover &__heart {
      @apply visible opacity-100;
    }

    @screen md {
      &__heart {
        @apply invisible opacity-0;
      }

      &__toggle:checked+&__heart {
        @apply visible opacity-100;
      }
    }
  }
}

// Горизорнтальный скролл
.scrolling {
  @screen md {
    height: var(--scroll-height);
  }

  &__image {
    @screen md {
      width    : 130%;
      height   : 130%;
      transform: translate3d(var(--scroll-moving), 0, 0);
    }
  }
}

// Квиз
.quiz {
  &-slider {
    &[data-quiz="stop"] .swiper-button-next {
      @apply opacity-50 pointer-events-none;
    }

    &[data-quiz-end] &-bottom {
      @apply invisible opacity-0;
    }
  }
}

// Время
.timer {
  &__stopwatch {
    &::after {
      @apply content-auto block relative -top-2 bg-current rounded-max w-4 h-4;
    }
  }
}