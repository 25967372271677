.like {
  @apply block cursor-pointer;

  &-heart {
    @apply flex items-center justify-center bg-white bg-opacity-40 text-red text-opacity-50 text-24 stroke-red rounded-max w-10 h-10 ease-linear duration-2;
  }

  &-toggle:checked+&-heart {
    @apply text-opacity-100;
  }
}