.btn-glow {
  &::before {
    animation: glowing 20s linear infinite;

    @apply content-auto absolute -z-1 -inset-1 rounded-inherit opacity-0 blur-sm ease-in-out duration-3;

    background: {
      image: linear-gradient(120deg, #ff304f, #581b98, #482ff7, #fc5185, #17b978, #ea5455, #2b1de8, #dd00f3);
      size : 400%;
    }
  }

  &::after {
    background-color: var(--btn-color);

    @apply content-auto absolute -z-1 inset-0 rounded-inherit ease-in-out duration-3;
  }

  @apply z-1;

  @media(hover) {
    &:hover::before {
      @apply opacity-100;
    }
  }
}

@keyframes glowing {

  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }
}