.rating {
  @apply flex items-center flex-row-reverse w-max;

  @media(hover) {

    &-star:hover,
    &-star:hover~&-star {
      @apply text-opacity-100;
    }
  }

  &-star {
    @apply text-second text-opacity-50 text-24 cursor-pointer duration-2;

    &~& {
      @apply mr-3;
    }
  }

  &-input:checked~&-star {
    @apply text-opacity-100;
  }
}