.inverted {
  perspective: 600px;

  &[data-inverted] &-before {
    transform: rotateX(0);
  }

  &[data-inverted] &-after {
    transform: rotateX(180deg);

    @apply absolute inset-0;
  }

  &[data-inverted="after"] &-before {
    transform: rotateX(-180deg);
  }

  &[data-inverted="after"] &-after {
    transform: rotateX(0);
  }

  @apply relative;

  @media(hover) {
    &:not([data-inverted]):hover &-before {
      transform: rotateY(-180deg);
    }

    &:not([data-inverted]):hover &-after {
      transform: rotateY(0);
    }
  }

  &:not([data-inverted]) &-before {
    transform: rotateY(0deg);

    @apply absolute inset-0;

    @media(pointer: coarse) {
      transform: rotateY(-180deg);
    }
  }

  &:not([data-inverted]) &-after {
    transform: rotateY(180deg);

    @media(pointer: coarse) {
      transform: rotateY(0);
    }
  }

  &-before,
  &-after {
    transform-style    : preserve-3d;
    backface-visibility: hidden;

    @apply w-full h-full duration-5 ease-in-out;
  }
}